html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  /* font-size: 62.5%; */

  --main-color: rgb(2, 121, 233);
}

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  font-size: 1rem;
}

h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.6rem;
}

h3 {
  font-size: 1.2rem;
}

img {
  width: 100%;
  height: auto;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

input:focus,
button:focus {
  outline: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.autocomplete-dropdown-container {
	border-bottom: honeydew;
	border-left: honeydew;
	border-right: honeydew;
	border-top: 1px solid #e6e6e6;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	position: relative;
	z-index: 1000;
	border-radius: 0 0 2px 2px;
	width: 100%;
	overflow: auto;
}

.suggestion-item {
	padding: 16px 14px;
}

.suggestion-item--active {
	padding: 16px 14px;
	background-color: rgba(0, 0, 0, 0.04);
}

.rdrMonths {
  min-height: 305px;
}

.MuiSnackbar-root {
  white-space: pre-wrap;
}